import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import {
  GALLERY_CONSTS,
  ProGallery,
  type CreateMediaUrl,
  type CustomComponents,
} from 'pro-gallery';
import { useEnvironment, useExperiments, useTranslation } from '@wix/yoshi-flow-editor';
import {
  getVideoSettingsWithProGalleryValues,
  isLayoutSlider,
  isLayoutTextOnImage,
  PG_LAYOUT_INFO_SIZE_TYPE_OPTIONS,
  resolveId,
  type LayoutName,
  type LayoutSettingsType,
  type PgLayoutImageCropTypeOptions,
  type POST_LIST_WIDGET_DEFAULTS,
  type PostAction,
  type Section,
  type VideoSection,
  type LayoutType,
  type Layout,
} from '@wix/communities-blog-client-common';
import { EXPERIMENT_USE_LAYOUT_FIXER } from '@wix/communities-blog-experiments';
import { isClickTargetItemMedia, isPGEventItemClicked } from '../../constants/pro-gallery-events';
import {
  HOVERING_BEHAVIOUR,
  LAYOUT_IMAGE_RATIO_MAP,
  TEXT_BOX_ALIGNMENT_MAP,
  TEXT_PLACEMENT,
} from '../../constants/pro-gallery-options';
import { useFeedBorderWidth } from '../../hooks/use-feed-border-width';
import { useIsFeedDesignEnabled } from '../../hooks/use-is-feed-design-enabled';
import useLayoutProps from '../../hooks/use-layout-props';
import {
  getFeedBorderColor,
  getIsCreatedWithResponsiveEditor,
  getLayoutCardsRowType,
  getLayoutContentHeight,
  getLayoutContentHeightType,
  getLayoutImageAlignment,
  getLayoutImageCropType,
  getLayoutImageProportions,
  getLayoutImageRatio,
  getLayoutImageResizingMode,
  getLayoutImageWidth,
  getLayoutMargins,
  getLayoutPostSize,
  getLayoutPostsPerRow,
  getLayoutSidesPadding,
  getLayoutSpacing,
  getUseMobileLayoutSettings,
} from '../../selectors/app-settings-selectors';
import { getProGalleryViewMode } from '../../selectors/progallery-view-mode-selectors';
import {
  getContainer,
  getPGEmptyCoverImage,
  getScrollingElement,
  getWrapperStyles,
  isUsingResponsiveStyling,
} from '../../services/post-list-pro-gallery';
import { getIsMobile } from '../../store/basic-params/basic-params-selectors';
import { type NormalizedPost } from '../../types';
import LoadMore, { type LoadMoreProps } from '../load-more/load-more';
import Loader from '../loader';
import { useResponsiveContext } from '../responsive-listener/responsive-context';
import { useActions, useSelector } from '../runtime-context';
import calculateContentHeight from './calculate-content-height';
import { createInfoRenderer } from './create-info-renderer';
import { createOptions, type ProGalleryOptions } from './gallery-options';
import { getPGFallbackImageSize, mapPostToPGItem } from './map-post-to-pro-gallery-item';
import { PostListProGalleryLayoutFixer } from './post-list-pro-gallery-layout-fixer';
import { resizeMediaUrl } from './resizeMediaUrl';
import { useScrollBase } from './use-scroll-base';
import styles from './post-list-pro-gallery.scss';

type LayoutDefaults = Partial<typeof POST_LIST_WIDGET_DEFAULTS>;

type PickedLayoutOptions = Pick<
  ProGalleryOptions,
  | 'showArrows'
  | 'arrowsSize'
  | 'autoSlide'
  | 'pauseTime'
  | 'arrowsPosition'
  | 'arrowsColor'
  | 'loop'
>;

type BaseProps = {
  allPosts: NormalizedPost[];
  canSeeMoreButton: (post: NormalizedPost) => boolean;
  className?: string;
  currentPagePosts: NormalizedPost[];
  domId?: string;
  hideCoverImage?: boolean;
  isLoading: boolean;
  layoutDefaults?: LayoutDefaults;
  layoutOptions?: PickedLayoutOptions;
  /** Unsafe to use by itself. Safe version is returned by useSlice() */
  layoutType?: Layout | LayoutType;
  /** Unsafe to use by itself. Safe version is returned by useSlice() */
  layoutName?: LayoutName;
  /** Unsafe to use by itself. Safe version is returned by useSlice() */
  section: Section;
  pageStart?: number;
  showCreatePostAction: boolean;
  visibleActions?: PostAction[];
};

type Props =
  | BaseProps
  | (BaseProps & {
      entityCount: number;
      loadMore: LoadMoreProps['loadMore'];
    });

/** Renders items as <article> instead of <div>. These props aren't public */
const internalGalleryProps = {
  elementsOverride: { item: 'article' },
} as const;

const PostListProGallery: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { isEditor, isRTL } = useEnvironment();
  const { experiments } = useExperiments();
  const scrollingElementRef = useRef(getScrollingElement());
  const { borderWidth } = useFeedBorderWidth();
  const { getPostClassName } = useIsFeedDesignEnabled();
  const actions = useActions();

  const slice = useSlice({
    section: props.section,
    layoutType: props.layoutType,
    layoutName: props.layoutName,
    layoutDefaults: props.layoutDefaults,
  });

  const useLayoutFixerExperimentEnabled = experiments.enabled(EXPERIMENT_USE_LAYOUT_FIXER);

  useEffect(() => {
    if (slice.createdWithResponsiveEditor) {
      document.documentElement.classList.add('enable-scroll');
    }
  }, [slice.createdWithResponsiveEditor]);

  const options = useMemo(() => {
    const container = getContainer({
      borderWidth,
      isEditor,
      useLayoutFixerExperimentEnabled,

      layoutType: slice.layoutType,
      section: slice.section!,

      createdWithResponsiveEditor: slice.createdWithResponsiveEditor,
      hostWidth: slice.hostWidth,
      isMobile: slice.isMobile,
      layoutContentHeight: slice.layoutContentHeight,
      layoutImageRatio: slice.layoutImageRatio,
      layoutMargins: slice.layoutMargins,
      layoutPostSize: slice.layoutPostSize,
      rootWidth: slice.rootWidth,
      useMobilePostListLayout: slice.useMobilePostListLayout,
    });

    return createOptions(
      slice.layoutType,
      {
        isRTL,
        containerWidth: container.width,
        hideCoverImage: props.hideCoverImage,
        layoutAutoHeightEnabled: slice.layoutAutoHeightEnabled,
        layoutCardsRowType: slice.layoutCardsRowType,
        layoutContentHeight: slice.layoutContentHeight,
        layoutImageCropType: slice.layoutImageCropType,
        layoutImageProportions: slice.layoutImageProportions,
        layoutImageRatio: slice.layoutImageRatio,
        layoutImageResizingMode: slice.layoutImageResizingMode,
        layoutImageWidth: slice.layoutImageWidth,
        layoutPostSize: isLayoutSlider(slice.layoutType) ? slice.hostWidth : slice.layoutPostSize,
        layoutPostsPerRow: slice.layoutPostsPerRow,
        layoutSpacing: slice.layoutSpacing,
        textBoxAlignment: slice.textBoxAlignment,
        videoSettings: slice.videoSettings,
        ...props.layoutOptions,
      },
      {
        width: borderWidth,
        color: slice.borderColor,
      },
    );
  }, [
    borderWidth,
    isEditor,
    isRTL,
    useLayoutFixerExperimentEnabled,

    props.hideCoverImage,
    props.layoutOptions,

    slice.borderColor,
    slice.createdWithResponsiveEditor,
    slice.hostWidth,
    slice.isMobile,
    slice.layoutAutoHeightEnabled,
    slice.layoutCardsRowType,
    slice.layoutContentHeight,
    slice.layoutImageCropType,
    slice.layoutImageProportions,
    slice.layoutImageRatio,
    slice.layoutImageResizingMode,
    slice.layoutImageWidth,
    slice.layoutMargins,
    slice.layoutPostSize,
    slice.layoutPostsPerRow,
    slice.layoutSpacing,
    slice.layoutType,
    slice.rootWidth,
    slice.section,
    slice.textBoxAlignment,
    slice.useMobilePostListLayout,
    slice.videoSettings,
  ]);

  const videoSettingsPlayMode = slice.videoSettings.play;
  const eventsListener = useCallback(
    (eventName, eventData) => {
      if (
        !isLayoutTextOnImage(slice.layoutType) &&
        isPGEventItemClicked(eventName) &&
        isClickTargetItemMedia(eventData)
      ) {
        const currentPost = props.allPosts.find((post) => resolveId(post) === eventData.id);

        if (
          !currentPost?.slug ||
          (currentPost.coverImage?.videoMetadata && videoSettingsPlayMode === 'onClick')
        ) {
          return;
        }

        actions.navigateProGalleryWithinPostPage(currentPost.slug);
      }
    },
    [props.allPosts, slice.layoutType, videoSettingsPlayMode, actions],
  );

  const infoRenderer = useMemo(
    () =>
      createInfoRenderer({
        getPostClassName,
        options,
        prevAllPosts: [],

        allPosts: props.allPosts,
        canSeeMoreButton: props.canSeeMoreButton,
        layoutName: slice.layoutName,
        layoutType: slice.layoutType,
        section: slice.section!,
        visibleActions: props.visibleActions,

        layoutSidesPadding: slice.layoutSidesPadding,
        textBoxAlignment: slice.textBoxAlignment,
      }),
    [
      options,
      getPostClassName,
      props.allPosts,
      props.canSeeMoreButton,
      props.visibleActions,
      slice.layoutName,
      slice.layoutType,
      slice.section,
      slice.textBoxAlignment,
      slice.layoutSidesPadding,
    ],
  );

  const hoveringBehavior = 'hoveringBehaviour' in options ? options.hoveringBehaviour : undefined;

  const customComponents = useMemo(() => {
    if (!infoRenderer) {
      return undefined;
    }

    return {
      customHoverRenderer:
        hoveringBehavior === HOVERING_BEHAVIOUR.NEVER_SHOW ? undefined : infoRenderer,
      customInfoRenderer: infoRenderer,
    };
  }, [infoRenderer, hoveringBehavior]);

  const items = useMemo(() => {
    const emptyCoverImage = getPGEmptyCoverImage(slice.layoutType);
    const fallbackSize = getPGFallbackImageSize(options);

    return props.allPosts.map((post) =>
      mapPostToPGItem(post, props.hideCoverImage, emptyCoverImage, fallbackSize),
    );
  }, [props.allPosts, props.hideCoverImage, slice.layoutType, options]);

  const PostListProGalleryComponent = React.memo(() => {
    const container = getContainer({
      borderWidth,
      isEditor,
      useLayoutFixerExperimentEnabled,

      createdWithResponsiveEditor: slice.createdWithResponsiveEditor,
      hostWidth: slice.hostWidth,
      isMobile: slice.isMobile,
      layoutContentHeight: slice.layoutContentHeight,
      layoutImageRatio: slice.layoutImageRatio,
      layoutMargins: slice.layoutMargins,
      layoutPostSize: slice.layoutPostSize,
      layoutType: slice.layoutType,
      rootWidth: slice.rootWidth,
      section: slice.section!,
      useMobilePostListLayout: slice.useMobilePostListLayout,
    });

    const wrapperStyles = getWrapperStyles({
      container,
      createdWithResponsiveEditor: slice.createdWithResponsiveEditor,
      isMobile: slice.isMobile,
      layoutMargins: slice.layoutMargins,
      layoutPostSize: slice.layoutPostSize,
      layoutType: slice.layoutType,
      section: slice.section!,
    });

    const containerRef = React.useRef(null);
    const scrollBase = useScrollBase(containerRef);
    const htmlId = props.domId ?? 'pro-blog';
    const deviceType = slice.isMobile
      ? GALLERY_CONSTS.deviceType.MOBILE
      : GALLERY_CONSTS.deviceType.DESKTOP;

    return (
      <div
        key="pg-container"
        data-hook="post-list-pro-gallery-container"
        style={wrapperStyles}
        className={classNames(
          `post-list-pro-gallery-${slice.layoutName}`,
          { 'is-mobile': slice.isMobile },
          slice.section,
          slice.isMobile && items.length === 1 ? 'post-list-pro-gallery-flat' : '',
          slice.textBoxAlignment === TEXT_PLACEMENT.SHOW_ON_THE_RIGHT
            ? 'gallery-left-align'
            : 'gallery-right-align',
          styles.overrides,
          props.className,
        )}
        ref={containerRef}
      >
        <PostListProGalleryLayoutFixer id={htmlId} items={items} options={options}>
          {(isPrerenderMode) => (
            <ProGallery
              {...internalGalleryProps}
              id={htmlId}
              items={items}
              options={options}
              container={{ ...container, scrollBase }}
              scrollingElement={scrollingElementRef.current}
              eventsListener={eventsListener}
              viewMode={slice.viewMode}
              deviceType={deviceType}
              customComponents={customComponents as CustomComponents}
              createMediaUrl={resizeMediaUrl as CreateMediaUrl}
              proGalleryRegionLabel={t('pro-gallery.parent-container-aria-label')}
              isPrerenderMode={isPrerenderMode}
              shouldDisableItemFocus={true}
            />
          )}
        </PostListProGalleryLayoutFixer>
      </div>
    );
  });

  return 'loadMore' in props && props.loadMore ? (
    <LoadMore
      loadMore={props.loadMore}
      loader={<Loader />}
      isLoading={props.isLoading}
      hasMore={props.allPosts.length < props.entityCount}
      pageStart={props.pageStart}
    >
      <PostListProGalleryComponent />
    </LoadMore>
  ) : (
    <PostListProGalleryComponent />
  );
};

function useSlice({
  section,
  layoutDefaults = {},
  layoutName,
  layoutType,
}: Pick<Props, 'section' | 'layoutType' | 'layoutName' | 'layoutDefaults'>) {
  const { rootWidth } = useResponsiveContext();
  const layoutProps = useLayoutProps({ layoutType, section });

  const hostWidth = useSelector((_, host) => host.dimensions?.width as number);
  const createdWithResponsiveEditor = useSelector(getIsCreatedWithResponsiveEditor);
  const usingResponsiveStyling = isUsingResponsiveStyling({
    createdWithResponsiveEditor,
    section: layoutProps.section!,
  });

  const layoutMarginsFromSettings = useSelector((state) =>
    getLayoutMargins({
      state,
      section: layoutProps.section,
      layoutType: layoutProps.layoutType,
    }),
  );

  const layoutMargins = usingResponsiveStyling ? 0 : layoutMarginsFromSettings;

  const layoutAutoHeightEnabled = useSelector(
    (state) =>
      getLayoutContentHeightType({
        state,
        section: layoutProps.section,
        layoutType: layoutProps.layoutType,
      }) === PG_LAYOUT_INFO_SIZE_TYPE_OPTIONS.AUTO,
  );
  const unsafeBorderColor = useSelector((state) =>
    getFeedBorderColor({ state, section: layoutProps.section! }),
  );

  const isMobile = useSelector(getIsMobile);
  const layoutContentHeight =
    useSelector((state) =>
      layoutAutoHeightEnabled
        ? calculateContentHeight(state, layoutProps.section!, layoutProps.layoutType)
        : getLayoutContentHeight({
            state,
            section: layoutProps.section,
            layoutType: layoutProps.layoutType,
          }),
    ) ?? layoutDefaults.contentHeight!;

  const textBoxAlignment = useSelector(
    (state) =>
      TEXT_BOX_ALIGNMENT_MAP[
        getLayoutImageAlignment({
          state,
          section: layoutProps.section,
          layoutType: layoutProps.layoutType,
        })!
      ],
  );
  const layoutImageProportions = useSelector(
    (state) =>
      getLayoutImageProportions({
        state,
        section: layoutProps.section,
        layoutType: layoutProps.layoutType,
      })!,
  );
  const layoutImageWidth = useSelector(
    (state) =>
      getLayoutImageWidth({
        state,
        section: layoutProps.section,
        layoutType: layoutProps.layoutType,
      })!,
  );
  const layoutImageResizingMode = useSelector((state) =>
    getLayoutImageResizingMode({
      state,
      section: layoutProps.section,
      layoutType: layoutProps.layoutType,
    }),
  );
  const layoutImageRatio = useSelector(
    (state) =>
      LAYOUT_IMAGE_RATIO_MAP[
        getLayoutImageRatio({
          state,
          section: layoutProps.section,
          layoutType: layoutProps.layoutType,
        }) ?? layoutDefaults.imageRatio!
      ],
  );
  const layoutImageCropType = useSelector(
    (state) =>
      getLayoutImageCropType({
        state,
        section: layoutProps.section,
        layoutType: layoutProps.layoutType,
      }) as unknown as PgLayoutImageCropTypeOptions,
  );
  const layoutPostSize = useSelector((state) =>
    getLayoutPostSize({
      state,
      hostWidth,
      rootWidth,
      layoutMargins,
      section: layoutProps.section!,
      layoutType: layoutProps.layoutType as LayoutSettingsType,
    }),
  );
  const layoutCardsRowType = useSelector(
    (state) =>
      getLayoutCardsRowType({
        state,
        section: layoutProps.section,
        layoutType: layoutProps.layoutType,
      }) ?? layoutDefaults.layoutCardsRowType!,
  );
  const layoutPostsPerRow = useSelector(
    (state) =>
      getLayoutPostsPerRow({
        state,
        section: layoutProps.section,
        layoutType: layoutProps.layoutType,
      }) ?? layoutDefaults.layoutPostsPerRow!,
  );
  const layoutSidesPadding = useSelector(
    (state) =>
      getLayoutSidesPadding({
        state,
        section: layoutProps.section,
        layoutType: layoutProps.layoutType,
      }) ?? layoutDefaults.sidesPadding!,
  );
  const layoutSpacing = useSelector(
    (state) =>
      getLayoutSpacing({
        state,
        section: layoutProps.section,
        layoutType: layoutProps.layoutType,
      }) ?? layoutDefaults.spacing!,
  );
  const viewMode = useSelector((state) => getProGalleryViewMode(state, rootWidth));
  const videoSettings = useSelector((state) =>
    getVideoSettingsWithProGalleryValues(state, layoutProps.section as VideoSection),
  );
  const useMobilePostListLayout = useSelector(getUseMobileLayoutSettings);

  return {
    hostWidth,
    section: layoutProps.section,
    layoutType: layoutProps.layoutType,
    layoutName: layoutName ?? layoutProps.layoutName,
    createdWithResponsiveEditor,
    layoutAutoHeightEnabled,
    rootWidth,
    isMobile,
    borderColor:
      typeof unsafeBorderColor === 'string'
        ? unsafeBorderColor
        : unsafeBorderColor.value || unsafeBorderColor.themeName!,
    layoutContentHeight,
    textBoxAlignment,
    layoutImageProportions,
    layoutImageWidth,
    layoutImageResizingMode,
    layoutImageRatio,
    layoutImageCropType,
    layoutMargins,
    layoutPostSize,
    layoutCardsRowType,
    layoutPostsPerRow,
    layoutSidesPadding,
    layoutSpacing,
    viewMode,
    videoSettings,
    useMobilePostListLayout,
  };
}

export default PostListProGallery;
